import { type ReactNode } from "react";
import { useAtomValue } from "jotai";
import { PostHogProvider } from "posthog-js/react";

import { isAuthenticatedVar } from "@/variables/globalVar";

import { ChannelProvider } from "shared/channel";
import { ApiProvider } from "./ApiProvider";

const postHogApiKey = import.meta.env.VITE_REACT_PUBLIC_POSTHOG_KEY;
const postHogApiHost = import.meta.env.VITE_REACT_PUBLIC_POSTHOG_HOST;
const postHogUiHost = import.meta.env.VITE_REACT_PUBLIC_POSTHOG_UI_HOST;

const postHogOptions = {
  api_host: postHogApiHost,
  ...(postHogUiHost ? { ui_host: postHogUiHost } : {})
};

const channelUrl = import.meta.env.VITE_REACT_APP_CHANNEL_ENDPOINT;

export interface ProvidersProps {
  children: ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedVar);

  return (
    <PostHogProvider apiKey={postHogApiKey} options={postHogOptions}>
      <ApiProvider>
        <ChannelProvider
          channelUrl={channelUrl}
          isAuthenticated={isAuthenticated}
        >
          {children}
        </ChannelProvider>
      </ApiProvider>
    </PostHogProvider>
  );
}
