import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import "shared/shared.css";
import "./styles/globals.css";

import { Providers } from "@/providers";
import { Router } from "@/components/Router";
import reportWebVitals from "./reportWebVitals";

import "@/lib/sentry";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <Providers>
      <Router />
    </Providers>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
